.controls-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.control-button {
    padding: 10px 15px;
    background-color: rgb(232, 196, 154);
    color: rgb(87, 50, 4);
    border: 2px solid rgb(87, 50, 4);
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.control-button:hover {
    background-color: rgb(151, 128, 101);
    color: rgb(233, 222, 207)
}