.meme-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    /* Adjust as needed */
    margin: 0 auto;
}

.canvas-wrapper {
    width: auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 1);
    /* Light gray background */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    margin-bottom: 20px;
    /* Space between canvas and controls */
}

.canvas-background {
    padding: 15px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 15px;
}

.canvas-container {
    background-color: #fff;
    border: 1px solid #cecece;
}

.meme-canvas {
    width: 100%;
    height: auto;
    display: block;
    /* Removes any extra space below the canvas */
}

.error-message {
    color: red;
    margin-top: 10px;
}

.image-thumbnails-container {
    display: flex;
    align-items: center;
    position: relative;
}

.image-thumbnails {
    display: flex;
    overflow-x: hidden;
    /* Hide overflow */
    width: 480px;
    /* Adjust to show 3 thumbnails */
    -webkit-overflow-scrolling: touch;
    /* Enables smooth scrolling on iOS */
    scroll-behavior: smooth;
    user-select: none;
    /* Prevents text selection during drag */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.thumbnail {
    flex: 0 0 auto;
    /* Prevent shrinking */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.arrow {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
}

.arrow.left {
    left: 0;
}

.arrow.right {
    right: 0;
}

.control-bar {
    display: flex;
    /* Added display flex */
    margin-top: 5px;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px;
    width: auto;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.control-bar button {
    background-color: #111827;
    border: 1px solid transparent;
    border-radius: 50px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex: 0 0 auto;
    font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none #6B7280 solid;
    text-decoration-thickness: auto;
    transition-duration: .2s;
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
}

.control-bar button:hover {
    background-color: #374151;
}

.control-bar button::focus {
    box-shadow: none;
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.control-bar button:active {
    box-shadow: #422800 2px 2px 0 0;
    transform: translate(2px, 2px);
}