.main-container {
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 700px;
    /* height: calc(100vh - 60px); */
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto;
}

.main-container .header {
    margin-bottom: 20px;
}

.main-container h1,
.main-container h2 {
    margin: 0;
    text-align: center;
    font-family: Arial, sans-serif;
}

.main-container h3 {
    margin-bottom: 20px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 15px;
    color: #000;
    font-size: 0.9em;
    font-weight: 400;
}

.main-container h2 {
    margin-top: 15px;
    text-align: center;
    margin-bottom: 10px;
    color: #000;
    font-size: 1.5em;
    font-weight: 600;
}